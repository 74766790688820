import { ImageUploadResponse, OrderedImage } from '@api/endpoints'
import { defaultImageDropzoneOptions } from '@controls/file'
import { FormUploadManagerParams } from './FormUploadManager'

export const defaultImageFileInputOptions: FormUploadManagerParams<unknown, unknown>['options'] = {
  dropzoneOptions: defaultImageDropzoneOptions
}

type OrderedItem = Pick<OrderedImage, 'orderNumber'>

export const reorderOrderedItemArray = <T extends OrderedItem>(array: Array<T>): Array<T> =>
  array
    .concat()
    .sort((a, b) => a.orderNumber - b.orderNumber)
    .map((v, index) => ({
      ...v,
      orderNumber: index
    }))

export const mergeOrderedItemArrays = (
  previous: Array<OrderedImage>,
  items: Array<ImageUploadResponse>
): Array<OrderedImage> =>
  reorderOrderedItemArray([
    ...previous,
    ...items.map((item, index) => ({
      ...item,
      orderNumber: previous.length + index
    }))
  ])

export const removeFromOrderedItemArrays = <T extends OrderedItem>(array: Array<T>, index: number): Array<T> =>
  reorderOrderedItemArray(array.filter((_, i) => i !== index))
