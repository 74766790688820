import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { hasValue, parseOptionalIntNanSafe } from '@digital-magic/ts-common-utils'
import { ImageUploadResponse, VehicleId } from '@api/endpoints'
import { getVehiclePrice } from '@api/endpoints/vehicles'
import { CarDetailed } from '@api/endpoints/admin'
import { Button } from '@mui/material'
import {
  useMutationCarUpdate,
  useMutationUploadCar360Image,
  useMutationUploadCarImage,
  useQueryGetCar
} from '@hooks/ReactQuery/admin'
import { FormTextField, useZodForm } from '@controls/Form'
import { ManagedImage } from '@controls/ManagedImage'
import { AdminPage } from '@layout/AdminPage'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer'
import { FlexContainer } from '@layout/FlexContainer'
import { FormOrderedImagesUploadManager, FormUploadManager } from '@forms/FormUploadManager'
import { defaultImageFileInputOptions } from '@forms/FormUploadManager/utils'
import { useQueryGetVehicleById } from '@hooks/ReactQuery/vehicles'
import { CarParams } from './types'

const FormValuesSchema = CarDetailed.omit({ id: true })
type FormValues = z.infer<typeof FormValuesSchema>

export const CarsDetailPage: React.FC = () => {
  const params = useParams<CarParams>()
  const { t } = useTranslation()

  const carId: VehicleId | undefined = parseOptionalIntNanSafe(params.carId)

  const getCar = useQueryGetCar(carId)
  const getCarDetails = useQueryGetVehicleById(carId)
  const updateCar = useMutationCarUpdate()
  const upload360Image = useMutationUploadCar360Image()
  const uploadCarImage = useMutationUploadCarImage()

  const isLoading: boolean =
    upload360Image.isPending || uploadCarImage.isPending || getCar.isFetching || updateCar.isPending

  const form = useZodForm({ schema: FormValuesSchema })

  React.useEffect(() => {
    if (getCar.data) {
      form.reset(getCar.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCar.data])

  const uploadImage = (file: File): Promise<ImageUploadResponse> =>
    uploadCarImage.mutateAsync(file).then((v) => ({
      imageUrl: v.imageUrl,
      thumbnailUrl: v.thumbnailUrl
    }))

  const onSubmit = (data: FormValues): void => {
    if (carId) {
      updateCar.mutate({
        id: carId,
        ...data
      })
    }
  }

  const onDeleteImage360 = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    form.setValue('image360Url', undefined)
  }

  const vehicle = getCarDetails.data

  return (
    <AdminPage>
      <h1>{t('pages.admin.cars.title')}</h1>
      {vehicle && (
        <ul>
          <li>
            {t('pages.admin.cars.carModel')}:{' '}
            {[vehicle.make, vehicle.model, vehicle.modelExtra].filter(hasValue).join(', ')}
          </li>
          <li>
            {t('pages.admin.cars.registrationNumber')}: {vehicle.mainData.registrationNumber}
          </li>
          <li>
            {t('pages.admin.cars.price')}: {getVehiclePrice(vehicle)}
          </li>
        </ul>
      )}
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FlexContainer vertical>
          <FormTextField
            control={form.control}
            name="videoUrl"
            label={t('pages.admin.cars.update.form.videoUrl')}
            disabled={isLoading}
          />

          <FormUploadManager
            label={t('pages.admin.cars.update.form.image360Url')}
            control={form.control}
            name="image360Url"
            disabled={isLoading}
            multiple={false}
            options={defaultImageFileInputOptions}
            upload={upload360Image.mutateAsync}
            onUpload={(result) => form.setValue('image360Url', result[0])}
            renderValue={(value) => (
              <ManagedImage src={value} style={{ maxWidth: 420 }} alt="Image 360 degrees" onDelete={onDeleteImage360} />
            )}
          />
          <FormOrderedImagesUploadManager
            label={t('pages.admin.cars.update.form.attachments')}
            control={form.control}
            name="attachments"
            disabled={isLoading}
            upload={uploadImage}
          />
          <ActionButtonsContainer noTopGap>
            <Button variant="contained" type="submit" disabled={isLoading}>
              {t('global.buttons.submit')}
            </Button>
          </ActionButtonsContainer>
        </FlexContainer>
      </form>
    </AdminPage>
  )
}
