import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd'
import * as React from 'react'
import clsx from 'clsx'
import { CurriedHandler } from '@digital-magic/ts-common-utils'
import { OrderedImage } from '@api/endpoints'
import { ManagedImage, ManagedImageProps } from '@controls/ManagedImage'
import styles from './OrderedManagedImages.module.css'

export type OrderedManagedImagesProps = {
  images: Array<OrderedImage>
  disabled?: boolean
  onDragEnd: (result: DropResult) => void
  onDeleteAttachment: CurriedHandler<number, React.MouseEvent<HTMLButtonElement>>
  altPrefix: ManagedImageProps['alt']
}

export const OrderedManagedImages: React.FC<OrderedManagedImagesProps> = ({
  images,
  disabled,
  altPrefix,
  onDragEnd,
  onDeleteAttachment
}) => {
  return (
    <div className={styles.orderedManagedImagesContainer}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-images-list" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={clsx({ 'droppable-container': true, dragging: snapshot.isDraggingOver })}
              {...provided.droppableProps}
            >
              {images.map((value, index) => (
                <Draggable key={value.thumbnailUrl} draggableId={value.thumbnailUrl} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className={clsx({ 'draggable-item': true, dragging: snapshot.isDragging })}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ManagedImage
                        className={styles.imageContainer}
                        disabled={disabled}
                        src={value.thumbnailUrl}
                        alt={`${altPrefix} #${index}`}
                        onDelete={onDeleteAttachment(index)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
