import * as React from 'react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { ImageUploadResponse } from '@api/endpoints'
import { uploadCarImage } from '@api/endpoints/admin'
import { QueryErrorHandler, useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'

export const useMutationUploadCarImage = (): UseMutationResult<ImageUploadResponse, unknown, File> => {
  const defaultErrorHandler = useDefaultQueryErrorHandlerPublic()

  const mutation = useMutation({
    mutationFn: uploadCarImage
  })

  const errorHandler: QueryErrorHandler = (e) => {
    defaultErrorHandler(e)
  }

  React.useEffect(() => {
    if (mutation.error) {
      errorHandler(mutation.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.error])

  return mutation
}
