import * as React from 'react'
import { Controller, FieldPath, FieldValues, PathValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FN, Handler } from '@digital-magic/ts-common-utils'
import { uploadFilesList } from '@api/endpoints/forms/utils'
import { CommonFormFieldProps } from '@controls/Form/types'
import { FileInput, FileInputProps } from '@controls/file'

export type FormUploadManagerParams<
  TUploadResult,
  TField extends PathValue<TFieldValues, TName>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = CommonFormFieldProps<TFieldValues> &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'form' | 'name' | 'onChange' | 'children'> &
  Readonly<{
    name: TName
    label: FileInputProps['label']
    options: Omit<FileInputProps['options'], 'inputText'> & { inputText?: string }
    upload: FN<File, Promise<TUploadResult>>
    onUpload: Handler<Array<TUploadResult>>
    renderValue?: FN<TField, React.ReactElement>
  }>

export const FormUploadManager = <
  TUploadResult,
  TField extends PathValue<TFieldValues, TName>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  disabled,
  multiple,
  upload,
  onUpload,
  renderValue,
  options: { inputText, inputTextDragActive, ...otherOptions },
  ...fileUploadManagerProps
}: FormUploadManagerParams<TUploadResult, TField, TFieldValues, TName>): React.ReactElement => {
  const { t } = useTranslation()
  const [uploadedFiles, setUploadedFiles] = React.useState(0)
  const [totalFiles, setTotalFiles] = React.useState(0)

  const incrementUploadedFiles = (): void => setUploadedFiles((uploadedFiles) => uploadedFiles + 1)
  const uploadFiles = uploadFilesList(upload, incrementUploadedFiles, incrementUploadedFiles)

  const handleFilesChange = async (value: Array<File>): Promise<void> => {
    setUploadedFiles(0)
    setTotalFiles(value.length)
    const uploaded = await uploadFiles(value)
    onUpload(uploaded)
  }

  const isUploading = uploadedFiles < totalFiles

  const dynamicInputText = isUploading
    ? t('global.fileUpload.uploading', { current: uploadedFiles, total: totalFiles })
    : inputText ?? t('global.fileUpload.uploadPhoto')

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FileInput
          disabled={disabled || isUploading}
          onFilesSelected={handleFilesChange}
          name={field.name}
          error={fieldState.error !== undefined}
          errorText={fieldState.error?.message}
          multiple={multiple}
          inputVisible={!field.value || multiple}
          options={{
            inputText: dynamicInputText,
            inputTextDragActive: inputTextDragActive ?? t('global.fileUpload.dropToUpload'),
            ...otherOptions
          }}
          {...fileUploadManagerProps}
        >
          {field.value && renderValue?.(field.value)}
        </FileInput>
      )}
    />
  )
}
