import * as React from 'react'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { CarDetailed, updateCar } from '@api/endpoints/admin'
import { QueryErrorHandler, useDefaultQueryErrorHandlerPublic, UseMutationProps } from '@hooks/ReactQuery'
import { queryKeyGetCarAny, queryKeyGetCarsAll } from './queryKeys'

export const useMutationCarUpdate = ({ onSuccess }: UseMutationProps = {}): UseMutationResult<
  void,
  unknown,
  CarDetailed
> => {
  const queryClient = useQueryClient()
  const defaultErrorHandler = useDefaultQueryErrorHandlerPublic()

  const mutation = useMutation({
    mutationFn: updateCar
  })

  const errorHandler: QueryErrorHandler = (e) => {
    defaultErrorHandler(e)
  }

  React.useEffect(() => {
    if (mutation.error) {
      errorHandler(mutation.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.error])

  React.useEffect(() => {
    if (mutation.isSuccess) {
      void queryClient.invalidateQueries({ queryKey: queryKeyGetCarAny })
      void queryClient.invalidateQueries({ queryKey: queryKeyGetCarsAll })
      void onSuccess?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess])

  return mutation
}
