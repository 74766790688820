import * as React from 'react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { UrlString, UrlStringSchema } from '@api/endpoints'
import { uploadCar360Image } from '@api/endpoints/admin'
import { QueryErrorHandler, useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'

export const useMutationUploadCar360Image = (): UseMutationResult<UrlString, unknown, File> => {
  const defaultErrorHandler = useDefaultQueryErrorHandlerPublic()

  const mutation = useMutation({
    mutationFn: (data: File) => uploadCar360Image(data).then((v) => UrlStringSchema.parse(v))
  })

  const errorHandler: QueryErrorHandler = (e) => {
    defaultErrorHandler(e)
  }

  React.useEffect(() => {
    if (mutation.error) {
      errorHandler(mutation.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.error])

  return mutation
}
