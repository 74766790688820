import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { hasValue } from '@digital-magic/ts-common-utils'
import { VehicleId } from '@api/endpoints'
import { CarDetailed, getCar } from '@api/endpoints/admin'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetCarById } from './queryKeys'

export const useQueryGetCar = (carId: VehicleId | undefined): UseQueryResult<Readonly<CarDetailed> | undefined> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()

  const query = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeyGetCarById(carId ?? -1),
    queryFn: () => getCar(carId ?? -1),
    enabled: hasValue(carId)
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
