import * as React from 'react'
import { FieldPathByValue, FieldValues, PathValue, useController } from 'react-hook-form'
import { DropResult } from '@hello-pangea/dnd'
import { reorderArray } from '@digital-magic/ts-common-utils'
import { ImageUploadResponse, OrderedImage } from '@api/endpoints'
import {
  defaultImageFileInputOptions,
  FormUploadManager,
  FormUploadManagerParams,
  mergeOrderedItemArrays,
  OrderedManagedImages,
  removeFromOrderedItemArrays
} from '@forms/FormUploadManager'

export const FormOrderedImagesUploadManager = <
  TField extends PathValue<TFieldValues, TName>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPathByValue<TFieldValues, Array<OrderedImage>> = FieldPathByValue<
    TFieldValues,
    Array<OrderedImage>
  >
>({
  control,
  name,
  ...props
}: Omit<
  FormUploadManagerParams<ImageUploadResponse, TField, TFieldValues, TName>,
  'multiple' | 'onUpload' | 'renderValue' | 'options'
>): React.ReactElement => {
  const { field } = useController({ name, control })

  const mergeUploadedAttachments = (uploaded: Array<ImageUploadResponse>): Array<OrderedImage> =>
    mergeOrderedItemArrays(field.value, uploaded)

  const handleDeleteAttachment =
    (index: number) =>
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.preventDefault()
      field.onChange(removeFromOrderedItemArrays(field.value, index))
    }

  const handleDragEnd = (result: DropResult): void => {
    if (result.destination && result.source.index) {
      const reordered = reorderArray(
        field.value as Array<OrderedImage>,
        result.source.index,
        result.destination?.index
      ).map((item, index) => ({
        ...item,
        order: index
      }))
      field.onChange(reordered)
    }
  }

  return (
    <FormUploadManager
      control={control}
      name={name}
      multiple={true}
      options={defaultImageFileInputOptions}
      onUpload={(result) => field.onChange(mergeUploadedAttachments(result))}
      renderValue={(value: Array<OrderedImage>) => (
        <OrderedManagedImages
          images={value}
          altPrefix="Image"
          disabled={props.disabled}
          onDragEnd={handleDragEnd}
          onDeleteAttachment={handleDeleteAttachment}
        />
      )}
      {...props}
    />
  )
}
