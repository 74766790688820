import * as React from 'react'
import { Button } from '@mui/material'
import styles from './ManagedImage.module.css'
import clsx from 'clsx'

type BasicImgProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

export type ManagedImageProps = BasicImgProps &
  Readonly<{
    src: BasicImgProps['src']
    alt: Required<BasicImgProps>['alt']
    disabled?: boolean
    onDelete: (e: React.MouseEvent<HTMLButtonElement>) => void
  }>

export const ManagedImage: React.FC<ManagedImageProps> = ({ onDelete, className, src, alt, disabled, ...props }) => (
  <div className={clsx(styles.managedImageContainer, className)}>
    <Button variant="contained" className={styles.btnDelete} disabled={disabled} onClick={onDelete}>
      ❌
    </Button>
    <img src={src} alt={alt} {...props} />
  </div>
)
